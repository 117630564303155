<template>
  <div class='lg:mt-8 px-2 lg:px-0'>
    <section class='py-8 flex flex-col lg:flex-row justify-start gap-x-4 items-start'>
      <div class='flex flex-col-reverse lg:flex-row justify-start lg:justify-between items-start w-full lg:w-2/5 flex-grow-0 flex-shrink-0'>
        <h1 class='text-2xl font-semibold text-gray-900 pl-2 lg:pl-0 my-4'>Venue</h1>
        <img 
          src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/venue/swiss_grand_hotel_logo.jpg'
          class='image-link block h-auto lg:h-48 w-full lg:w-48 border bg-white hover:shadow-md cursor-pointer'
          @click='imageLink("logo")' />
      </div>
      <div class=''>
        <h3 class='text-lg font-semibold mb-2 px-2'>Swiss Grand Hotel</h3>
        <a 
          href='http://www.swissgrand.co.kr/' 
          target='_blank' 
          class='flex flex-row items-center justify-start gap-x-2 py-1 px-2 hover:bg-gray-100'>
          <globe-alt-icon class='h-5'/>
          <span
            class='block'>
            Homepage
          </span>
        </a>
        <a 
          href='tel:+82-1670-0005' 
          class='flex flex-row items-center justify-start gap-x-2 py-1 px-2 hover:bg-gray-100'>
          <phone-icon class='h-5'/>
          <span class=''>+82-2-3216-5656</span>
        </a>
        <a href='https://www.google.com/maps/place/swiss+grand+hotel+seoul/@37.5890761,126.9321163,17z/data=!3m1!4b1!4m8!3m7!1s0x357c986a45705bb5:0x224be1d558aa33!5m2!4m1!1i2!8m2!3d37.5890761!4d126.934305'
          target='_blank'
          class='flex flex-row items-center justify-start gap-x-2 py-1 px-2 hover:bg-gray-100'>
          <location-marker-icon class='h-5'/>
          <span class=''>353, Yeonhui-ro, Seodaemun-gu, Seoul, Korea 03650</span>
        </a>
        <div
          class='flex flex-row items-center justify-start gap-x-2 py-1 px-2 hover:bg-gray-100'>
          <printer-icon class='h-5'/>
          <span class=''>+82-2-3216-7799</span>
        </div>
      </div>
    </section>
    <section class='py-8 flex flex-col lg:flex-row justify-start gap-x-4 items-start'>
      <div class='flex flex-col-reverse lg:flex-row justify-start lg:justify-between items-start w-full lg:w-2/5 flex-grow-0 flex-shrink-0'>
        <h1 class='text-2xl font-semibold text-gray-900 pl-2 lg:pl-0 my-4'>Floorplan Map</h1>
        <img 
          src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/venue/floorplan_map_4f.jpg'
          class='image-link block h-auto lg:h-48 w-full lg:w-48 border bg-white hover:shadow-md cursor-pointer'
          @click='imageLink("hallMap_4f")' />
      </div>
      <p class='px-2'>Swiss Grand Hotel Convention Center 4F</p>
    </section>
    <section class='py-8 flex flex-col lg:flex-row justify-start gap-x-4 items-start'>
      <div class='flex flex-col-reverse lg:flex-row justify-start lg:justify-between items-start w-full lg:w-2/5 flex-grow-0 flex-shrink-0'>
        <h1 class='text-2xl font-semibold text-gray-900 pl-2 lg:pl-0 my-4'></h1>
        <img 
          src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/venue/floorplan_map_3f.jpg'
          class='image-link block h-auto lg:h-48 w-full lg:w-48 border bg-white hover:shadow-md cursor-pointer'
          @click='imageLink("hallMap_3f")' />
      </div>
      <p class='px-2'>Swiss Grand Hotel Convention Center 3F</p>
    </section>
    <section class='py-8 flex flex-col lg:flex-row justify-start gap-x-4 items-start'>
      <div class='flex flex-col-reverse lg:flex-row justify-start lg:justify-between items-start w-full lg:w-2/5 flex-grow-0 flex-shrink-0'>
        <h1 class='text-2xl font-semibold text-gray-900 pl-2 lg:pl-0 my-4'></h1>
        <img 
          src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/venue/floorplan_map_1f.jpg'
          class='image-link block h-auto lg:h-48 w-full lg:w-48 border bg-white hover:shadow-md cursor-pointer'
          @click='imageLink("hallMap_1f")' />
      </div>
      <p class='px-2'>Swiss Grand Hotel Convention Center 1F</p>
    </section>
  </div>
</template>

<script>
import { GlobeAltIcon, PhoneIcon, LocationMarkerIcon, PrinterIcon } from '@vue-hero-icons/outline'

export default {
  name: 'EventLocation',
  components: {
    GlobeAltIcon,
    PhoneIcon,
    LocationMarkerIcon,
    PrinterIcon
  },
  methods: {
    imageLink (link) {
      let url = ''
      if (link === 'hallMap_4f') {
        url = 'https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/venue/floorplan_map_4f.jpg'
      } else if (link ===  'hallMap_3f') {
        url = 'https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/venue/floorplan_map_3f.jpg'
      } else if (link ===  'hallMap_1f') {
        url = 'https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/venue/floorplan_map_1f.jpg'
      } else if (link === 'logo') {
        url = 'http://www.swissgrand.co.kr/'
      } else {
        url = 'http://www.swissgrand.co.kr/'
      }
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang='scss' scoped>
  .img-link {
    transition: all 0.15s ease-in-out;
  }
</style>

